import React from 'react';
import { graphql } from "gatsby"
import LegacyContainer from '../components/legacy-container';
import SEO from '../components/SEO';
import FullWidthImage from '../components/modular/FullWidthImage/FullWidthImage';
import PageTitle from '../components/modular/PageTitle/PageTitle';
import ParagraphText from '../components/modular/ParagraphText/ParagraphText';
import Gallery from '../components/modular/Gallery/Gallery'
import PressPack from '../components/modular/PressPack/PressPack';

export default function About({ data }) {
  const aboutData = data.allMarkdownRemark.edges[0].node;
  const images = aboutData.frontmatter.imageGallery.map(data => {
    return data.image;
  });
  const pressPackUrl = aboutData.frontmatter.pressPackDownloadUrl;


  return (
    <LegacyContainer>
      <SEO pageTitle="About" />
      <div className="content-container">
        <div className="content">
          <PageTitle title="About" />
          <FullWidthImage image={aboutData.frontmatter.headlineImage} />
          <ParagraphText html={aboutData.html} />
          {images && images.length > 0 && <Gallery images={images} />}
          {pressPackUrl && pressPackUrl.length > 0 && <PressPack pressPackUrl={pressPackUrl} />}
        </div>
      </div>
    </LegacyContainer>
  );
}

export const aboutPageQuery = graphql`
  query AboutPageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "about-page" } }}
      limit: 1
    ) {
      edges {
        node {
          frontmatter {
            headlineImage,
            imageGallery {
              image,
              description
            }
            pressPackDownloadUrl
          }
          html
        }
      }
    }
  }
`
